import { Box, InputAdornment, Stack, Tooltip, Typography } from "@mui/material"
import { If } from "lib/@components/switch"
import { Editable } from "lib/@components/editable"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { convertToMinMaxNumber } from "lib/number"
import { useEffect, useMemo, useRef } from "react"
import noop from "lib/noop"
import { SummaryDataChanged } from "event-definitions"
import { prevent } from "lib/prevent"
import { MinHeightBox } from "lib/@components/minHeightBox"
import { HighlightBox } from "lib/@components/highlightBox"
import { BoundSimpleCombo } from "lib/@components/combos/boundSimpleCombo"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export const ZERO_U_WORDS = (
    <Typography variant="body2">
        0U (Zero U) which means the frequency is to be defined by the user based on a site-specific assessment.
    </Typography>
)

export function Frequency() {
    const { task } = useBoundContext()

    if (!task?.frequency) return null

    return (
        <MinHeightBox ml={1} className="frequency">
            <Editable pb={0.4} pt={0.4} editor={<FrequencyEditor />}>
                <HighlightBox path="frequency">
                    <Tooltip
                        enterDelay={300}
                        enterNextDelay={300}
                        title={
                            task.frequency.period[0] === "U" ? (
                                ZERO_U_WORDS
                            ) : (
                                <Stack>
                                    <Typography variant="caption" component="div">
                                        Frequency
                                    </Typography>
                                    <Typography variant="body2">
                                        Every {task.frequency.interval}{" "}
                                        {task.frequency.period.pluralize(task.frequency.interval)}
                                    </Typography>
                                </Stack>
                            )
                        }
                    >
                        <Box
                            // sx={{ fontSize: "90%", [theme.breakpoints.down("lg")]: { fontSize: "74%" } }}
                            borderRadius={1}
                            minWidth={"6.5em"}
                            p={0.5}
                            pl={1}
                            pr={1}
                            bgcolor="background.paper"
                        >
                            <If not truthy={task.frequency.period[0] === "U"}>
                                <strong>Every </strong>
                            </If>
                            <span>
                                {task.frequency.interval}
                                {task.frequency.period[0]}
                            </span>
                        </Box>
                    </Tooltip>
                </HighlightBox>
            </Editable>
        </MinHeightBox>
    )
}

function FrequencyEditor({ cancel = noop, onClick = noop, readOnly }) {
    const me = useRef()
    const time = useRef(0)
    const input = useRef()
    const [frequency, setFrequency] = useBoundValue("frequency.interval", "")
    const [period, setPeriod] = useBoundValue("frequency.period", "")
    const [click, handleBlur] = useMemo(
        () => [
            function click() {
                if (readOnly) return
                time.current = Date.now()
                onClick()
                setTimeout(() => input.current?.focus())
            },
            function handleBlur() {
                if (readOnly) return
                if (!frequency || !+frequency) {
                    setPeriod("Unknown")
                    setFrequency("")
                }
                if (Date.now() - time.current > 70) {
                    cancel()
                }
            },
        ],
        [readOnly, onClick, frequency, setPeriod, setFrequency, cancel]
    )
    useEffect(() => {
        if (!!frequency && (!period || period === "Unknown")) {
            setPeriod("Month")
        }
    }, [period, frequency, setPeriod])
    return (
        <Box ref={me} onBlur={handleBlur}>
            <BoundTextField
                variant="outlined"
                label="Frequency"
                data-cy="frequency-input"
                onClick={prevent(noop)}
                fullWidth={false}
                onChanged={SummaryDataChanged.raiseOnce}
                sideEffectsOnBlur
                inputRef={input}
                sx={{ width: 122, m: 0, p: 0 }}
                InputProps={{
                    readOnly,
                    sx: { pr: 0.2 },
                    endAdornment: !!frequency && (
                        <InputAdornment position="end">
                            <BoundSimpleCombo
                                onChanged={SummaryDataChanged.raiseOnce}
                                aria-label="Period"
                                readOnly={readOnly}
                                options={{
                                    Hour: "Hour",
                                    Day: "Day",
                                    Week: "Week",
                                    Month: "Month",
                                    Year: "Year",
                                }}
                                onClick={click}
                                field="frequency.period"
                            />
                        </InputAdornment>
                    ),
                }}
                transformOutOnBlur={convertToMinMaxNumber(0, 1200)}
                field="frequency.interval"
                size="small"
            />
        </Box>
    )
}
