import { Box, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { If } from "lib/@components/switch"
import { Editable } from "lib/@components/editable"
import noop from "lib/noop"
import { SummaryDataChanged } from "event-definitions"
import { MinHeightBox } from "lib/@components/minHeightBox"
import { HighlightBox } from "lib/@components/highlightBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { useState } from "react"
import { setFromEvent } from "lib/setFromEvent"
import { useRefresh } from "lib/@hooks/useRefresh"

export function Time() {
    const { task, editMode } = useBoundContext()
    if (!task) return null
    const amount = task.minutes * 1000 * 60
    const isMobile = false
    if (amount === 0 && !editMode) {
        return null
    }
    const minutes = Math.floor(task.minutes)
    const rounded = Math.round(task.minutes)
    const seconds = Math.round((task.minutes % 1) * 60)

    return (
        /* (editMode || task.$showMinutes || inComparison)  && */ <If
            truthy={isMobile}
            then={
                <HighlightBox path="minutes">
                    <MinHeightBox className="time">
                        <Typography variant="caption">
                            {minutes}:{seconds.toString().padLeft(2, "0")}
                        </Typography>
                    </MinHeightBox>
                </HighlightBox>
            }
            else={
                <MinHeightBox className="time" ml={1} minWidth="3.3em">
                    <Editable editor={<TimeEditor />}>
                        <HighlightBox path="minutes">
                            {Number.isNaN(minutes) ? (
                                <Tooltip title="Duration not specified">
                                    <Box px={1}>-</Box>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    enterDelay={300}
                                    enterNextDelay={300}
                                    title={
                                        minutes < 5 ? (
                                            <Stack>
                                                <Typography variant="caption">Estimated Task Duration</Typography>
                                                <Typography variant="body2">
                                                    {minutes} minutes {seconds} seconds
                                                </Typography>
                                            </Stack>
                                        ) : (
                                            <Stack>
                                                <Typography variant="caption">Estimated Task Duration</Typography>
                                                <Typography variant="body2">{minutes} minutes</Typography>
                                            </Stack>
                                        )
                                    }
                                >
                                    <Box>
                                        <If
                                            truthy={minutes < 5 && seconds !== 0}
                                            then={`${minutes}m ${seconds}s`}
                                            else={`${rounded}m`}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                        </HighlightBox>
                    </Editable>
                </MinHeightBox>
            }
        />
    )
}

function convertToTime(v) {
    if (typeof v === "string") return v
    return `${Math.floor(v)}:${Math.round((v % 1) * 60)
        .toString()
        .padLeft(2, "0")}`
}

function convertFromTime(v = "") {
    try {
        if (!v.trim().length) return 0
        const parts = v.split(":").map((v) => +v)
        if (parts.some((p) => Number.isNaN(p))) return 0
        parts[1] = parts[1] || 0
        return parts[0] + parts[1] / 60.0
    } catch (e) {
        return 0
    }
}

function TimeEditor({ cancel = noop }) {
    const refresh = useRefresh()
    const [time, setTime] = useBoundValue("minutes")
    const [current, setCurrent] = useState(time ? convertToTime(time) : "")

    return (
        <TextField
            className="bind minutes"
            variant="outlined"
            label="Duration"
            value={current}
            onChange={setFromEvent(setCurrent)}
            sx={{ width: 80 }}
            onBlur={blur}
            field="minutes"
            size="small"
        />
    )

    function blur() {
        setTime(convertFromTime(current))
        cancel()
        SummaryDataChanged.raiseOnce()
        refresh()
    }
}
