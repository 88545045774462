import { UOM_TO_DESCRIPTION } from "routes/schedule/lib/uom-to-input"
import { Editable } from "lib/@components/editable"
import { SummaryDataChanged } from "event-definitions"
import { HighlightBox } from "lib/@components/highlightBox"
import { Box, Tooltip } from "@mui/material"
import { BoundPopup } from "lib/@components/combos/boundPopup"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Frag } from "lib/@components/slot/frag"

export function UnitLabel({ defaultUnitOfMeasure }) {
    const { target, editMode, mustShowContent, inComparison } = useBoundContext()
    return (
        <Box ml={1} className="uom" flex={1}>
            <Editable
                sx={{
                    width:
                        editMode || mustShowContent || inComparison
                            ? 100
                            : target.unitOfMeasure !== "Nr"
                              ? "70px"
                              : "0px",
                }}
                pt={1}
                pb={1}
                editor={
                    <EditUnitLabel>
                        {UOM_TO_DESCRIPTION[target.unitOfMeasure ?? defaultUnitOfMeasure] ?? ""}
                    </EditUnitLabel>
                }
            >
                <Frag>
                    {(target.unitOfMeasure !== "Nr" || editMode || mustShowContent || inComparison) && (
                        <Box pt={0} lineHeight={0} mt={-0.5}>
                            <Tooltip arrow title="The unit of measurement for this task">
                                <HighlightBox path="unitOfMeasure">
                                    {UOM_TO_DESCRIPTION[target.unitOfMeasure] ?? ""}
                                </HighlightBox>
                            </Tooltip>
                        </Box>
                    )}
                </Frag>
            </Editable>
        </Box>
    )
}

function EditUnitLabel({ children, onClick }) {
    return (
        <BoundPopup
            label="UoM"
            onChanged={SummaryDataChanged.raiseOnce}
            aria-label="Unit of measure"
            field="unitOfMeasure"
            options={UOM_TO_DESCRIPTION}
            onClick={onClick}
        >
            {children}
        </BoundPopup>
    )
}
