export function convertToNumber(v) {
    const value = (v || "").trim()
    return value !== "" && value !== "-" ? +v : v
}

export function convertToNumberIfPossible(v) {
    if (!v) return v
    if (/[^0-9.+-]+/g.test(v.toString())) {
        return v
    }
    try {
        return +v
    } catch (e) {
        return v
    }
}

export function convertToMinMaxNumber(min, max) {
    return function convert(v) {
        if (typeof v === "number") {
            return convertToMinMax(v)
        }
        const value = (v || "").trim()
        if (value === "." || value === "-" || value.endsWith(".")) return value
        const result = parseFloat(value)
        if (Number.isNaN(result)) return ""
        return convertToMinMax(result)
    }

    function convertToMinMax(result) {
        if (min !== undefined) result = Math.max(min, result)
        if (max !== undefined) result = Math.min(max, result)
        return result
    }
}
